/* #181818 */
.quiz__card{
    display: block;

}

.quiz__card .quiz__nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
}

.quiz__card .back__btn{
    background-color: #353535;
    border: none;
    color: #fff;
    padding: 10px;
    border-radius: 50px;
    cursor: pointer;
    font-size: 10px;
    font-weight: bolder;
}

.question__card{
    background-color: #181818;
    padding: 20px 12px;
    border: 1px solid #181818;
    border-radius: 20px;
    margin-top: 50px;
}

.question__card h3{
    color: #fff;
    font-size: 16px;
    font-weight: bolder;
}
.question__card .options{
    display: grid;
    gap: 10px;
    margin-top: 20px;
}

.question__card .option__item{
    margin-top: 5px;
    background-color: #353535;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    color: #fff;
}