/* #181818 */
.cont {
    background: #181818;
    padding: 20px;
    border-radius: 20px;
    max-width: 500px;
    margin: auto;
    color: #fff;

}

.cont .short__header {
    font-family: Inter;
    font-size: 14.1px;
    font-weight: 500;
    line-height: 14.1px;
    text-align: left;
    color: rgb(217, 215, 215);

}

#content__type{
    display: flex;
    justify-content: space-between;
    font-size: 10px;
    font-weight: bolder;
}

.cont p {
    margin-top: 20px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #a19f9f;

}

.read__more{
    text-align: center;
    font-size: 20px;
    width: 50px;
    padding: 10px;
    margin: auto;
    border: 1px solid #597DC44D;
    border-radius: 100px;
    background: #3F7BE066;
    margin-top: 5px;

}


.content__footer{
    margin-top: 30px;
    width: 100%;
}

.cont .duration{
    float: right;
}



.StartBtn{
    color: #597DC44D;
    background: #597DC44D;
    
}

.navigate_back_button{
    background: none;
    border: none;
}

.navigate_back {
    color: #fff;
    font-size: 15px;
    margin-left: 10px;
    font-weight: 900;
}