/*=============== GOOGLE FONTS ===============*/
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap");

/*=============== VARIABLES CSS ===============*/
:root {
  --header-height: 4.2rem;

  /*========== Colors ==========*/
  --hue: 174;
  --sat: 63%;
  --first-color: rgb(176, 172, 172);
  --first-color-alt: #fff;
  --title-color: #fff;
  --text-color: #fff;
  --body-color: #000;
  --container-color: rgba(0, 0, 0, 1);

  /*========== Font and typography ==========*/
  --body-font: 'Open Sans', sans-serif;
  --h1-font-size: 1.5rem;
  --normal-font-size: .938rem;
  --tiny-font-size: .625rem;

  /*========== z index ==========*/
  --z-tooltip: 10;
  --z-fixed: 100;
}

@media screen and (min-width: 968px) {
  :root {
    --h1-font-size: 2.25rem;
    --normal-font-size: 1rem;
  }
}

/*=============== BASE ===============*/
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: var(--header-height) 0 0 0;
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  background-color: var(--body-color);
  color: #fff;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

/*=============== REUSABLE CSS CLASSES ===============*/
.section {
  padding: 4.5rem 0 2rem;
}

.section__title {
  font-size: var(--h1-font-size);
  color: var(--title-color);
  text-align: center;
  margin-bottom: 1.5rem;
}

.section__height {
  height: 100vh;
}

/*=============== LAYOUT ===============*/
.container {
  max-width: 968px;
  margin-left: 1rem;
  margin-right: 1rem;
}

/*=============== HEADER ===============*/
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--container-color);
  z-index: var(--z-fixed);
  transition: .4s;
}

/*=============== NAV ===============*/
.nav {
  height: var(--header-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fa-grip-vertical{
  margin-right: 20px;
}


.nav__brand__img{
  width: 80px;
}

.nav__img {
  width: 100px;
  margin-right: 0px;
}


/* .nav__logo {
  color: var(--title-color);
  font-weight: 600;
} */

.nav-link.active {
  background: #3F7BE0;

  color: white;
}

.active .nav__icon {
  background: #3F7BE0;
  color: white;
}

@media screen and (max-width: 767px) {
  .nav__menu {
    position: fixed;
    bottom: 10px;
    left: 0;
    right: 0;
    background-color: rgb(35, 33, 33);
    box-shadow: 0 -1px 12px hsla(var(--hue), var(--sat), 15%, 0.15);
    width: 70%;
    height: 4rem;
    padding: 0rem;
    display: grid;
    align-content: center;
    border-radius: 5rem;
    transition: .4s;
    margin: auto;
  }

}

.nav__list,
.nav__link {
  display: flex;
}

.nav__link {
  flex-direction: column;
  align-items: center;
  row-gap: 4px;
  color: var(--title-color);
  font-weight: 600;
}

.nav__list {
  justify-content: space-around;
}

.nav__name {
  font-size: var(--tiny-font-size);
  /* display: none;*/
  /* Minimalist design, hidden labels */
}

.nav__icon {
  font-size: 1.5rem;
  border: 2px solid rgba(53, 53, 53, 1);
  border-radius: 50%;
  padding: .5rem;
  background-color: rgba(53, 53, 53, 1);
  color: #fff;
  margin-top: 1rem;
}

/*Active link*/
.active-link {
  position: relative;
  color: var(--first-color);
  transition: .3s;
}

/* Minimalist design, active link */
/* .active-link::before{
  content: '';
  position: absolute;
  bottom: -.5rem;
  width: 4px;
  height: 4px;
  background-color: var(--first-color);
  border-radius: 50%;
} */

/* Change background header */
.scroll-header {
  box-shadow: 0 1px 12px hsla(var(--hue), var(--sat), 15%, 0.15);
}

/*=============== MEDIA QUERIES ===============*/
/* For small devices */
/* Remove if you choose, the minimalist design */
@media screen and (max-width: 320px) {
  .nav__name {
    display: none;
  }
}

/* For medium devices */
@media screen and (min-width: 576px) {
  .nav__list {
    justify-content: center;
    column-gap: 3rem;
  }
}

@media screen and (min-width: 767px) {
  body {
    margin: 0;
  }

  .section {
    padding: 7rem 0 2rem;
  }

  .nav {
    height: calc(var(--header-height) + 1.5rem);
    /* 4.5rem */
  }

  .nav__img {
    display: none;
  }

  .nav__icon {
    display: none;
  }

  .nav__name {
    font-size: var(--normal-font-size);
    /* display: block; */
    /* Minimalist design, visible labels */
  }

  .nav__link:hover {
    color: var(--first-color);
  }

  /* First design, remove if you choose the minimalist design */
  .active-link::before {
    content: '';
    position: absolute;
    bottom: -.75rem;
    width: 4px;
    height: 4px;
    background-color: var(--first-color);
    border-radius: 50%;
  }

  /* Minimalist design */
  /* .active-link::before{
      bottom: -.75rem;
  } */
}

/* For large devices */
@media screen and (min-width: 1024px) {
  .container {
    margin-left: auto;
    margin-right: auto;
  }
}