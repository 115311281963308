.badge__container,
.user__page {
  background-color: #181818;
  border: 1px solid #181818;
  padding: 15px;
  border-radius: 20px;
}

.nav__img {
  width: 52px;
  border-radius: 50%;
}

.badge__container .badges {
  margin: auto;
  text-align: center;
  align-items: center;
  justify-items: center;
  display: flex;
  flex-wrap: wrap;
  /* grid-template-columns: repeat(auto-fill, minmax(1fr, 50px)); */
  gap: 20px;

}

.badge__item {
  padding: 0px 10px;
  border-radius: 5px;
  font-size: 8px;
  font-weight: 800;
  border: 1px solid #8E8E93;
  color: #8E8E93;
}

.badge__item i {
  margin-right: 2px;
}

.novice {
  border: 1px solid #FF9500;
  color: #FF9500;
}

.senior {
  border: 1px solid #FFCC00;
  color: #FFCC00;
}


.enthusiast {
  border: 1px solid #34C759;
  color: #34C759;
}

.leader {
  border: 1px solid #00C7BE;
  color: #00C7BE;
}

.expert {
  border: 1px solid #1CC8FF;
  color: #1CC8FF;
}

.veteran {
  border: 1px solid #AF52DE;
  color: #AF52DE;
}

.master {
  border: 1px solid #7272eb;
  color: #7272eb;
}

.ultimate {
  border: 1px solid #fff;
  color: #fff;
}

.user__page .code__space {
  border: 1px solid #FFFFFF1A;
  border-radius: 20px;
  padding: 10px;
  margin: auto;
  text-align: center;
  background: #FFFFFF1A;


}

.user__page .code__space .copy__btn {
  text-align: end;
  border: 1px solid #FFFFFF1A;
  background: #FFFFFF1A;
  padding: 5px;
  border-radius: 10px;
  color: #fff;
  font-size: 10px;
  font-weight: 800;
  margin-left: 10px;
}

.progress {
  position: relative;
  background-color: #e9ecef;
  border-radius: 25px;
  overflow: hidden;
}

.progress-bar {
  position: relative;
  background: #3F7BE0;
  border-radius: 5px 50px 50px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bolder;
  color: white;
}

.progress-bar::after {
  content: "";
  position: absolute;
  top: 0;
  right: -10px;
  /* Adjust this value to change the size of the "cut" */
  width: 10px;
  height: 100%;
  background-color: #e9ecef;
  clip-path: polygon(50% 0%, 100% 0, 100% 100%, 50% 100%);
}


.images__trf{
  margin: auto;
  text-align: center;
  gap: 10px;
}

.images__trf i{
  font-size: 15px;
  color: #3F7BE0;
  margin-left: 8px;
}


.profile__image {
  width: 40px;
  border-radius: 50%;
  margin: auto;
  text-align: center;
  align-items: center;
}

.brand__img {
  width: 50px;
  margin: auto;
  text-align: center;
  align-items: center;
}

.ref__item{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
}

.ref__item i{
  margin-right: 4px;
  color: #7272eb;
}

.ref__not{
  border-radius: 50px;
  background-color: #FFFFFF1A;
  padding: 5px 10px;
  color: yellow;
}