.summery__nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
}

.summery__nav .back__btn{
    background-color: #353535;
    border: none;
    color: #fff;
    padding: 10px;
    border-radius: 50px;
    cursor: pointer;
    font-size: 10px;
    font-weight: bolder;
    margin-top: -20px;
}

.winner__bar, .quiz__ranking{
    color: #fff;
    margin-top: 50px;
    background-color: #181818;
    padding: 10px 20px;
    border-radius: 20px;
}

.winner__bar img{
    width: 100px;
    margin: 0 auto;
    display: block;
    margin-top: -50px;
}

.winner__bar p{
   color:  grey;
}

.winner__classes{
    display: flex;
    gap: 10px;
    margin-top: 30px;
    justify-content: space-between;
    align-items: center;
    text-align: center;

}

.winner__classes i{
    margin-right: 4px;
    font-size: 10px;
}

.winner__classes .Q{
    color: #4B8FFFF5;
}

.winner__classes .fa-file-circle-check{
    color: #61D617;
}

.winner__classes .fa-file-circle-xmark{
    color: #F95A49E5;
}

.leaderboard{
    margin-top: 20px;
    display: grid;
    gap: 10px;
}

.leaderboard .leader__items{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.leaderboard .leader__items img{
    width: 32px;
    border-radius: 50px;
}

