.home__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.item-container {
    border: 2px solid #181818;
    border-radius: 10px;
    padding: 10px;
    color: #fff;
}

.item-container img {
    width: 100%;
    height: 250px;
    border-radius: 10px;
}

.item-container .post__logo{
    width: 15px;
    height: 15px;
    border-radius: 50px;
    margin-right: 5px;
}

.item-container .left__item {
    display: flex;
    float: right;
    margin-right: 10px;
    margin-top: -20px;

}

.item-container .short__header{
    margin-top: 20px;
    max-width: 350px;
}

.item-container .left__item p{
    margin-right: 20px;
}

.item-container .lower__content {
    font-size: 12px;
    font-weight: 900;

}
.star__item {



}

/* .item-container {
    border: 1px solid rgb(20, 20, 20);
    border-radius: 20px;
    padding: 15px;
    background: linear-gradient(45deg, #3818fc, #6b6bfd 58.85%, #a479ff);
    position: relative;
    max-width: 500px;
    color: #fff;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.star__item {
    display: flex;
}



.item-container h2 {
    font-family: Inter;
    font-size: 28.21px;
    font-weight: 500;
    line-height: 28.21px;
    text-align: left;
    margin-top: 20px;
    color: #fff;

}

.item-container .short__header {
    max-width: 220px;
}

.item-container .short__header,
p {
    font-family: Inter;
    font-size: 14.1px;
    font-weight: 500;
    line-height: 14.1px;
    text-align: left;
    color: rgb(217, 215, 215);

}

.item-container .duration {
    margin-top: 10px;
}

.item-container img {
    max-width: 200px;
    height: 135px;
    border-radius: 10px;
} */

/* .arrange__content {
    display: flex;
    
}

.arrange__content h3{
    margin-top: 20px;
    max-width: 200px;
    text-align: left;
} */

.status__active {
    color: rgb(59, 220, 59);
}

.form-inline {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.form-inline input {
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #181818;
    background-color: #181818;
    color: white;
}

.form-inline input:focus,
.form-inline input:active {
    background-color: #181818;
    /* Maintain the dark grey background */
    border: 1px solid #181818;
    /* Maintain the border color */
    color: white;
    /* Maintain the text color */
    outline: none;
    /* Keep the outline removed */
}

.code__input {
    margin-right: 10px;

}

.apply__btn {
    flex-shrink: 0;
}

/* 

rgb(20, 20, 20)
rgb(121, 134, 134)
rgb(158, 169, 169)



*/

.inc__container {
    border: 1.5px solid rgb(121, 134, 134);
    border-radius: 10px;
    padding: 20px;
    background-color: rgb(20, 20, 20);
    max-width: 500px;
    margin: auto;
    text-align: start;
}

.inc__container img {
    border-radius: 5px;
    width: 100%;
    height: 200px;
}

.carousel .thumbs-wrapper {
    display: flex;
    justify-content: center;
}

.carousel .thumbs {
    justify-content: center;
    display: flex;
    width: auto;
    margin: 0 auto;
}