.task__container{
    display: grid;
    gap: 15px;

}

.task__container .task__item{
    border: 1px solid #353535;
    border-radius: 8px;
    padding: 5px 10px;
    width: 100%;
    font-size: 12px;
    background-color: #353535;
}


.task__container i{
    margin-right: 5px;
}
.task__container .btn__link{
    float: right;
    margin-top: -20px;
}